import { AiFunctionTypes } from '@eeedo/types';
import { faStars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18next from 'i18next';
import iziToast from 'izitoast';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

import type { ConnectedProps } from 'react-redux';

import Button from '../generic/Button/Button';
import OpenAIApi from 'src/api/OpenaiApi';
import { selectActiveTicket } from 'src/selectors/rootStateSelectors';

import type { State } from 'src/types/initialState';

type OwnProps = {
  commentId?: string;
  basicButton?: boolean;
  tinyButton?: boolean;
};

type AIFunctionButtonsProps = AIFunctionButtonsReduxProps & OwnProps;

const AIFunctionButtons = ({
  activeTicketTab,
  ticketType,
  ticketTypes,
  aiPrompts,
  commentId,
  UID
}: AIFunctionButtonsProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const triggerAIFunction = (id: number, allowMissingParams = false) => {
    setLoading(true);
    if (!activeTicketTab) {
      throw new Error('Something went wrong');
    }
    const contentId = parseInt(activeTicketTab.substring(3), 10);
    const selectedPrompt = aiPrompts.find((prompt) => prompt.id === id);
    if (!selectedPrompt) {
      throw new Error('Something went wrong');
    }
    iziToast.success({
      title: t('ai_function_buttons.started_processing_comment_with_ai_button', {
        functionName: selectedPrompt.name
      }),
      icon: 'icon check',
      timeout: 5000
    });

    OpenAIApi.triggerAIFunction({
      templateId: selectedPrompt.id,
      data: {
        contentId,
        commentId: commentId ? parseInt(commentId.substring(3), 10) : undefined,
        UID: parseInt(UID.substring(3), 10)
      },
      allowMissingParams,
      language: i18next.language
    })
      .then(() => {
        iziToast.success({
          title: t('ai_function_buttons.successfully_processed_comment_with_ai_button', {
            functionName: selectedPrompt.name
          }),
          icon: 'icon check',
          timeout: 5000
        });
        setLoading(false);
      })
      .catch((err) => {
        if (err?.isAxiosError && err.response?.status === 400 && 'missingParams' in err.response.data) {
          iziToast.warning({
            timeout: 0,
            progressBar: false,
            close: false,
            overlay: true,
            id: 'question',
            zindex: 999,
            message: t('ai_function_buttons.failed_to_process_missing_params', {
              missingParams: err.response.data.missingParams,
              functionName: selectedPrompt.name
            }),
            position: 'center',
            buttons: [
              [
                `<button><b>${t('GENERAL_CLOSE')}</b></button>`,
                (instance, toast) => {
                  instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');
                },
                true
              ],
              [
                `<button><b>${t('INIT_LOAD_CONTINUE_ANYWAY')}</b></button>`,
                (instance, toast) => {
                  triggerAIFunction(id, true);
                  instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');
                },
                true
              ]
            ]
          });
        } else {
          iziToast.error({
            title: t('ai_function_buttons.failed_to_process_comment_with_ai_button', {
              functionName: selectedPrompt.name
            }),
            icon: 'icon check',
            timeout: 5000
          });
        }
        setLoading(false);
        console.error('failed to trigger AI function:', err?.message);
      });
  };

  const filteredAiTemplates = useMemo(() => {
    const ticketTypeId = ticketTypes.find((type) => type.name === ticketType)?.id;
    if (!ticketTypeId) {
      return [];
    }

    return aiPrompts.filter((prompt) => prompt.ticketTypes.length === 0 || prompt.ticketTypes.includes(ticketTypeId));
  }, [aiPrompts, ticketType]);

  return (
    <Dropdown
      direction="right"
      disabled={filteredAiTemplates.length === 0 || loading}
      trigger={
        <Button
          iconLeft={<FontAwesomeIcon icon={faStars} />}
          content={t('ai_function_buttons.ai_function_button_dialog')}
          type="primary"
          size="sm"
        />
      }
      icon={false}
      onChange={(e, data) => triggerAIFunction(data.value as number)}
      scrolling
      selectOnBlur={false}
      selectOnNavigation={false}
      title={
        filteredAiTemplates.length > 0
          ? t('ai_function_buttons.choose_template_placeholder')
          : t('ai_function_buttons.search_no_results')
      }
    >
      <Dropdown.Menu>
        {filteredAiTemplates.map((prompt) => (
          <Dropdown.Item key={prompt.id} onClick={() => triggerAIFunction(prompt.id)}>
            {prompt.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = (state: State) => ({
  aiPrompts: state.aiFunctions.data.filter((AiFunction) => AiFunction.type === AiFunctionTypes.AiMenu),
  ticketTypes: state.ticketTypes,
  ticketType: selectActiveTicket(state)?.taskType,
  activeTicketTab: state.activeTicketTab,
  UID: state.userData.UID
});

const connector = connect(mapStateToProps, {});
type AIFunctionButtonsReduxProps = ConnectedProps<typeof connector>;

export default connector(AIFunctionButtons);
