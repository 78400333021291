import { Channels } from '@eeedo/types';
import { isEmpty, isEqual, omit, uniq, uniqBy } from 'lodash-es';
import React from 'react';
import { Translation } from 'react-i18next';
import { createFilter } from 'react-select';
import { Divider, Form, Label } from 'semantic-ui-react';

import type {
  PersonalData,
  ResponseTemplate,
  SenderEmail,
  Suggestion,
  TicketType,
  UserWithProfile
} from '@eeedo/types';
import type { SyntheticEvent } from 'react';

import Dropdown from '../../generic/Dropdown/Dropdown';
import Input from '../../generic/Input/Input';
import FormDropzoneDropdown from '../components/FormDropzoneDropdown';
import ReplyControlButtons from '../components/ReplyControlButtons';
import ReplyVCDrafts from '../components/ReplyVCDrafts';
import { KnowledgeBank } from '../KnowledgeBank';
import ReplyEmailSubmitModal from '../ReplyEmailSubmitModal';
import { ReplyMethod } from '../ReplyMethod';
import ReplyTemplates from '../ReplyTemplates';
import * as styles from './ReplyEmail.style';
import SelectedAttachment from './SelectedAttachment';
import FeatureFlags from 'src/api/FeatureFlags';
import ChannelType from 'src/Components/CommentIconContent/ChannelType';
import Button from 'src/Components/generic/Button/Button';
import { MultiDraggableDropdown, MultiDraggableDropdownProvider } from 'src/Components/MultiDraggableDropdown';
import { defaultCreatableSelectStyles } from 'src/Components/Utilities';
import { trimAndSplitRecipients } from 'src/Components/Utilities/recipients';
import { convertCaseNaming } from 'src/Utilities/helper';
import mapEmailValueToOptions from 'src/Utilities/mapEmailValueToOptions';
import { getAttachmentIdsFromContent, serializeContent } from 'src/Utilities/parseUtils';

import type { ReplyMethodProps } from '../ReplyMethod';
import type { sendEntityEvent } from 'src/actions/ticketsActions';
import type { IItem, IItemsCollection } from 'src/Components/MultiDraggableDropdown';
import type { TicketStatusAfterCommentSubmit } from 'src/types/Salesforce';
import type { Attachment, Comment, Entity, Ticket, UploadFileResult } from 'src/types/Ticket';

import './ReplyEmail.css';

const ReplyEditor = React.lazy(() => import('src/Components/Case/ReplyEditor'));

export interface ReplyEmailProps extends ReplyMethodProps<ReplyEmailState> {
  userData: PersonalData;
  to: string;
  attachments: Attachment[];
  senderEmails: SenderEmail[];
  ticketType: TicketType;
  taskType: TicketType;
  templates: ResponseTemplate[];
  drafts?: Partial<ReplyEmailState>;
  task: Ticket;
  entities: Entity[];
  suggestions: Suggestion[];
  users: UserWithProfile[];
  subject?: string;
  ticketTypes: TicketType[];
  canCloseAsDone: boolean;

  // TODO: typing for props functions
  onSubmit: (body: any) => Promise<Comment>;
  uploadFile: (ticketId: string, file: FormData) => Promise<UploadFileResult[]>;
  sendEntityEvent?: typeof sendEntityEvent;
  changeTicketStatus: (ticketId: string, uid: string, status: TicketStatusAfterCommentSubmit) => Promise<void>;
}

interface ReplyEmailState {
  content: string;
  contentTemplate: string;
  cc: string;
  showCc: boolean;
  subject: string;
  bcc: string;
  showBcc: boolean;
  to: string;
  from: string;
  isEmailOptionsOpen: boolean;
  isCaseDoneAfterSubmit: boolean;
  isSubmitModalOpen: boolean;
  isLoading: boolean;
  selectedAttachmentIds: string[];
  suggestionResults: Array<Suggestion>;
  searchLoading: boolean;
  selectedReplyTemplate: string | undefined;
  entitiesChanged?: boolean;
  closeWithStatus?: TicketStatusAfterCommentSubmit;
}

class ReplyEmail extends ReplyMethod<ReplyEmailProps, ReplyEmailState> {
  protected channel = ChannelType.Email;
  private emailOptions: { text: string; value: string }[];

  constructor(props: ReplyEmailProps) {
    super(props);
    this.state = this.getInitialState(this.props, false, false);
    this.setEmailOptions(props);
  }

  componentDidUpdate(prevProps: ReplyEmailProps) {
    if (
      // TODO: investigate why is entity order changing at some point
      !isEqual(
        this.mapEntitiesForComparison([...(prevProps.entities ?? [])]),
        this.mapEntitiesForComparison([...(this.props.entities ?? [])])
      )
    ) {
      this.setState(this.getInitialState(this.props, false, true, true, prevProps.to), () => {
        this.saveDraft(this.state);
      });
    }

    if (
      !isEqual(
        prevProps.senderEmails.find((email) => email.default),
        this.props.senderEmails.find((email) => email.default)
      )
    ) {
      this.setState(this.getInitialState(this.props, true, false), () => {
        this.saveDraft(this.state);
      });
    }
  }

  componentWillReceiveProps(nextProps: ReplyEmailProps) {
    if (!isEqual(nextProps.drafts, this.props.drafts) || this.props.taskId !== nextProps.taskId) {
      this.setState(this.getInitialState(nextProps, false, false, true));
    }

    this.setEmailOptions(nextProps);
  }

  getDraftChannel(): Channels {
    return Channels.email;
  }

  getDraftState(state: ReplyEmailState): Partial<ReplyEmailState> {
    return {
      content: state.content,
      contentTemplate: state.contentTemplate,
      isCaseDoneAfterSubmit: state.isCaseDoneAfterSubmit,
      subject: state.subject,
      to: state.to,
      cc: state.cc.replace(state.from, ''), // stops the system from sending a CC to itself in case of the email having arrived in the system being a CC
      from: state.from,
      bcc: state.bcc,
      selectedAttachmentIds: state.selectedAttachmentIds,
      isLoading: state.isLoading,
      isSubmitModalOpen: state.isSubmitModalOpen,
      isEmailOptionsOpen: state.isEmailOptionsOpen,
      selectedReplyTemplate: state.selectedReplyTemplate
    };
  }

  private mapEntitiesForComparison = (entities: Entity[]) =>
    [...(entities ?? [])]
      .sort((a, b) => (a._id > b._id ? 1 : -1))
      .map?.((entity) => omit(entity, ['isLoading', 'data.subEntities']));

  private setEmailOptions = (props: ReplyEmailProps) => {
    this.emailOptions = props.senderEmails.map((senderEmail: SenderEmail, index: number) => ({
      key: index,
      text: senderEmail.text,
      value: senderEmail.email
    }));
  };

  private getInitialState = (
    props: ReplyEmailProps,
    defaultFromChanged: boolean,
    entitiesChanged: boolean,
    keepShowCopyRecipients?: boolean,
    previousRecipients?: string
  ) => {
    let to: string | string[] = props.drafts?.to === undefined ? props.to : props.drafts?.to;
    const initialMail = props.senderEmails.find((email: SenderEmail) => email.default)?.email || '';
    const from = defaultFromChanged ? initialMail : props.drafts?.from || initialMail;

    if (entitiesChanged && previousRecipients !== undefined) {
      const currentPropsRecipientsArray = props.to.split(',').filter(Boolean);
      const previousPropsRecipientsArray = previousRecipients.split(',').filter(Boolean);

      let recipientsToBeAdded = currentPropsRecipientsArray.filter(
        (address) => !previousPropsRecipientsArray.includes(address)
      );

      const recipientsToBeRemoved = previousPropsRecipientsArray.filter(
        (address) => !currentPropsRecipientsArray.includes(address)
      );

      let currentRecipientsArray = typeof to === 'string' ? to.split(',') : to;
      const recipientOverlap = currentRecipientsArray.filter((address) => recipientsToBeAdded.includes(address));

      recipientsToBeAdded = recipientsToBeAdded.filter((address) => !recipientOverlap.includes(address));

      currentRecipientsArray = currentRecipientsArray.concat(recipientsToBeAdded);

      currentRecipientsArray = currentRecipientsArray.filter((address) => !recipientsToBeRemoved.includes(address));

      to = currentRecipientsArray.join(',');
    }

    // don't included sender in recipients
    if (!Array.isArray(to)) {
      const toArray: string[] = to.split(',');
      to = uniq(toArray).filter((email: string) => {
        return email.toLowerCase().trim() !== from.toLowerCase().trim();
      });
      to = to.toString();
    }

    const state: ReplyEmailState = {
      content: props.drafts?.content || '',
      contentTemplate: props.drafts?.contentTemplate || '',
      isCaseDoneAfterSubmit: props.drafts?.isCaseDoneAfterSubmit || false,
      subject: props.drafts?.subject || ' ' + props.subject || 'Re: ',
      to,
      from,
      cc: props.drafts?.cc || '',
      bcc: props.drafts?.bcc || '',
      showCc: keepShowCopyRecipients ? this.state.showCc : false,
      showBcc: keepShowCopyRecipients ? this.state.showBcc : false,
      isEmailOptionsOpen: props.drafts?.isEmailOptionsOpen || false,
      isLoading: props.drafts?.isLoading || false,
      isSubmitModalOpen: props.drafts?.isSubmitModalOpen || false,
      selectedAttachmentIds: props.drafts?.selectedAttachmentIds || [],
      suggestionResults: [],
      searchLoading: false,
      selectedReplyTemplate: props.drafts?.selectedReplyTemplate || undefined
    };

    return state;
  };

  protected clearFields = () => {
    const { to, subject } = this.props;

    this.updateState({
      content: '',
      subject,
      from: '',
      to,
      cc: '',
      bcc: '',
      selectedAttachmentIds: [],
      isLoading: false,
      isSubmitModalOpen: false,
      selectedReplyTemplate: undefined
    });
  };

  protected getSelectedAttachments = (selectAttachmentIds: string[]): Attachment[] =>
    this.props.attachments.filter(
      (attachment: Attachment) =>
        !attachment.isQuarantined && !attachment.deprecated && selectAttachmentIds.includes(attachment.id)
    );

  protected submitEmail = async (closeWithStatus?: TicketStatusAfterCommentSubmit) => {
    try {
      const { from, to: emailTo, cc, bcc, subject, content, selectedAttachmentIds } = this.state;
      // Froala blobs are replaced with cid references
      const attachmentIdsFromContent = getAttachmentIdsFromContent(content, this.props.attachments);
      const contentWithCids = serializeContent(content, this.props.attachments);
      const attachmentIds = uniq([...selectedAttachmentIds, ...attachmentIdsFromContent]);
      const to = trimAndSplitRecipients(emailTo)?.filter((to) => to);
      const title = subject.trim();
      const metaData = {
        cc: cc.length > 0 ? trimAndSplitRecipients(cc)?.filter((cc) => cc) : undefined,
        bcc: bcc.length > 0 ? trimAndSplitRecipients(bcc)?.filter((bcc) => bcc) : undefined,
        title,
        from,
        to,
        attachments: this.getSelectedAttachments(attachmentIds)
      };
      const contentSpecial = title?.length > 900 ? title.slice(900, -1) + ' \n\n ' + contentWithCids : contentWithCids;
      const ViestiketjuIdeet = {};
      Object.keys(this.props.task.case).map((caseDetailName) => {
        if (caseDetailName.indexOf('ViestiketjuId') != -1) {
          ViestiketjuIdeet[caseDetailName] = this.props.task.case[caseDetailName];
        }
      });
      const bodyOfRequest = {
        content: contentSpecial,
        sendAsMail: true,
        ViestiketjuIdeet,
        taskType: this.props.task.taskType,
        OsapuoliId: this.props.task.case.OsapuoliId || undefined,
        subject: title?.length > 900 ? title.slice(0, 900) : subject,
        channel: this.channel,
        direction: 'out',
        metaData,
        html: contentSpecial,
        ...metaData
      };

      if (this.props.sendEntityEvent) {
        const recipientEntities: { _id: string; _type: string }[] = [];

        bodyOfRequest.to?.forEach((recipient: string) => {
          const emailEntityMatch = this.props.entities.find((entity) => {
            return entity.data?.email === recipient;
          });
          if (emailEntityMatch) {
            recipientEntities.push({
              _id: emailEntityMatch._id,
              _type: emailEntityMatch._type
            });
          }
        });

        if (recipientEntities.length > 0) {
          await this.props.sendEntityEvent({
            type: 'contact',
            ticketType: this.props.ticketType.name,
            entities: recipientEntities,
            data: {
              direction: bodyOfRequest.direction,
              channel: bodyOfRequest.channel,
              to: bodyOfRequest.to,
              subject: bodyOfRequest.subject,
              content: bodyOfRequest.content,
              attachments: this.getSelectedAttachments(attachmentIds),
              contentId: convertCaseNaming(this.props.task.id, 'number', 'task')
            }
          });
        }
      }

      const response = await this.props.onSubmit(bodyOfRequest);
      if (response) {
        if (typeof closeWithStatus !== 'undefined') {
          this.props.changeTicketStatus(this.props.taskId, this.props.userData.UID, closeWithStatus);
        }
        this.clearFields();
      }
    } catch (error) {
      console.error('Failed to save ticket', error);
    } finally {
      this.updateState({
        closeWithStatus: undefined
      });
    }
  };

  private mapSuggestions = (suggestions: Suggestion[]) => {
    return suggestions
      .filter((suggestion: Suggestion) => {
        if (!isEmpty(suggestion.ticketTypes)) {
          return suggestion.type === 'email' && suggestion.ticketTypes.includes(this.props.ticketType.id);
        } else {
          return suggestion.type === 'email';
        }
      })
      .map((suggestion: Suggestion) => ({
        label: suggestion.name,
        value: suggestion.value,
        id: suggestion.id.toString()
      }));
  };

  private formatSuggestionOptionLabel = ({ label, value }: any) => (
    <span>
      {label ? <Label> {label}</Label> : ''} {value ? value : ''}
    </span>
  );

  private handleAddressesChange = ({ to, cc, bcc }: IItemsCollection) => {
    const itemsToString = (items: IItem[]) =>
      items
        .reduce<string[]>(
          (acc, curr) => uniq([...acc, ...mapEmailValueToOptions(curr.value).map(({ value }) => value)]),
          []
        )
        .join(',');

    const state = {
      ...this.state,
      to: itemsToString(to),
      cc: itemsToString(cc),
      bcc: itemsToString(bcc)
    };

    this.setState(state, () => {
      this.saveDraft(this.state);
    });
  };

  private getAddresses = () => ({
    to: mapEmailValueToOptions(this.state.to),
    bcc: mapEmailValueToOptions(this.state.bcc),
    cc: mapEmailValueToOptions(this.state.cc).filter(
      (o) => !this.state.from.toLowerCase().includes(o.value.toLowerCase())
    )
  });

  private formatCreateLabel = (text: string) => {
    return <span>{text}</span>;
  };

  private onUploadAttachment = async (attachmentFiles: File[]) => {
    attachmentFiles.map((file) => {
      const data = new FormData();
      data.append('attachments', file);
      return this.props.uploadFile(this.props.taskId, data).then((files) => {
        const attachments = files.map((att) => att.attachmentId);

        this.updateWithPreviousState((previous) => ({
          selectedAttachmentIds: [...previous.selectedAttachmentIds, ...attachments]
        }));

        return files;
      });
    });
  };

  public submitComment = async (closeWithStatus?: TicketStatusAfterCommentSubmit) => {
    if (!this.state.content.length || !this.state.to.length) {
      return;
    }

    this.updateState({ isSubmitModalOpen: true, closeWithStatus });
  };

  public renderReplyControlButtons = () => {
    const replyDisabled = this.state.content.length === 0 || this.state.to.length === 0;
    return (
      <ReplyControlButtons
        small={this.props.smallButtons}
        disabled={replyDisabled}
        disabledCloseAsDone={!this.props.canCloseAsDone}
        onClear={this.clearFields}
        onSaveDraft={this.disableVCDraft}
        onSubmit={this.submitComment}
        onSubmitAndTicketStatusChange={this.onSubmitAndTicketStatusChange}
      />
    );
  };

  private onSubmitAndTicketStatusChange = (ticketStatus?: TicketStatusAfterCommentSubmit) =>
    this.submitComment(ticketStatus);

  private disableVCDraft = () => this.saveVCDraft(false);

  private onAttachmentsChange = (addedAttachments: string[]) => {
    this.updateState({
      selectedAttachmentIds: [...this.state.selectedAttachmentIds, ...addedAttachments]
    });
  };

  private getAttachemntsOptions = () =>
    this.getAttachmentsOptions().filter(
      (attachment) => !this.state.selectedAttachmentIds.includes(attachment.value as any)
    );

  private removeSelectedAttachment = (attachmentId: string) => {
    this.setState({
      selectedAttachmentIds: this.state.selectedAttachmentIds.filter((id) => id !== attachmentId)
    });
  };

  private getToggleCopy = (key: 'showCc' | 'showBcc') => (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    this.updateState({ [`${key}`]: !this.state[`${key}`] });
  };

  private toggleCC = (e: SyntheticEvent) => {
    this.getToggleCopy('showCc')(e);
  };

  private toggleBCC = (e: SyntheticEvent) => {
    this.getToggleCopy('showBcc')(e);
  };

  render() {
    return (
      <Translation ns="translations">
        {(t) => (
          <>
            {/* MultiDraggableDropdown component is used only inside this component, why do we have such a overcomplicated data flow? (mix
              of component state, redux state and react context)
              TODO: refactor MultiDraggableDropdown use, get rid of context */}
            <MultiDraggableDropdownProvider
              itemsCollection={this.getAddresses()}
              setItemsCollection={this.handleAddressesChange}
            >
              <ReplyEmailSubmitModal
                isOpen={this.state.isSubmitModalOpen}
                canCloseAsDone={this.props.canCloseAsDone}
                content={this.state.content}
                cc={this.state.cc}
                subject={this.state.subject}
                bcc={this.state.bcc}
                to={this.state.to}
                from={this.state.from}
                selectedAttachmentIds={this.state.selectedAttachmentIds}
                attachments={this.props.attachments}
                closeWithStatus={this.state.closeWithStatus}
                closeModal={() => this.updateState({ isSubmitModalOpen: false })}
                submitEmail={this.submitEmail}
              />
              <Form reply style={styles.form} loading={this.state.isLoading}>
                <Form.Group>
                  <Form.Field width={'8'}>
                    {/* Senders */}
                    <label style={styles.sendersLabel}>{t('ADD_COMMENT_SENDER_EMAIL_ADDRESS')}</label>
                    <Dropdown
                      style={styles.sendersDropdown}
                      search
                      selectOnBlur={false}
                      value={this.state.from}
                      options={uniqBy(this.emailOptions, 'value')}
                      noResultsMessage={t('GENERAL_SEARCH_NO_RESULTS')}
                      onChange={(_event, data) => {
                        const email = this.props.senderEmails.find((x) => x.email === data.value);
                        this.updateState({ from: email ? email.email : '' });
                      }}
                    />
                  </Form.Field>

                  {/* Attachments */}
                  <Form.Field width={'4'} style={styles.attachments}>
                    <FormDropzoneDropdown
                      attachments={this.props.attachments.filter((a) => !a.isQuarantined)}
                      onChangeAttachments={this.onAttachmentsChange}
                      onDropAccepted={this.onUploadAttachment}
                      options={this.getAttachemntsOptions()}
                      value={this.state.selectedAttachmentIds}
                    />
                  </Form.Field>

                  {/* Canned response */}
                  <Form.Field width={'4'} style={styles.cannedResponse}>
                    <ReplyTemplates
                      userData={this.props.userData}
                      ticketType={this.props.ticketType}
                      templates={this.props.templates}
                      task={this.props.task}
                      channel={this.channel}
                      toAddresses={this.state.to}
                      ccAddresses={this.state.cc}
                      bccAddresses={this.state.bcc}
                      selectedOption={this.state.selectedReplyTemplate}
                      setSelectedOption={(value) => {
                        this.setState({ selectedReplyTemplate: value });
                      }}
                      setContent={(value) => {
                        this.updateState({ ...value });
                      }}
                      getCurrentContent={this.getCurrentContent}
                      insertAtCursor={this.editorRef.current ? this.insertAtCursor : undefined}
                      content={
                        this.state.subject && this.state.subject?.length > 900
                          ? this.state.subject.slice(900, -1) + ' \n\n ' + this.state.content
                          : this.state.content
                      }
                      entities={this.props.entities}
                      users={this.props.users}
                      replyType="email"
                    />
                  </Form.Field>
                </Form.Group>

                {/* Selected attachments */}
                {this.state.selectedAttachmentIds.length > 0 && (
                  <div className="reply-email-attachments">
                    {this.state.selectedAttachmentIds.map((id) => {
                      const attachment = this.props.attachments.find((at) => at.id === id)!;

                      return (
                        <SelectedAttachment
                          id={attachment.id}
                          fileName={attachment.fileName}
                          onClose={this.removeSelectedAttachment}
                        />
                      );
                    })}
                  </div>
                )}

                {/* Recipients */}
                <Form.Group>
                  <Form.Field width={'14'}>
                    <label style={styles.recipientsLabel}>{t('ADD_COMMENT_RECEPIENTS')}</label>
                    <MultiDraggableDropdown
                      name="to"
                      isClearable
                      hideSelectedOptions
                      formatOptionLabel={this.formatSuggestionOptionLabel}
                      options={this.mapSuggestions(this.props.suggestions)}
                      formatCreateLabel={() => this.formatCreateLabel(t('SELECT_ADD_NEW_RECIPIENT'))}
                      placeholder={t('ADD_COMMENT_RECEPIENTS_PLACEHOLDER')}
                      noOptionsMessage={() => t('SELECT_NO_OPTIONS')}
                      classNamePrefix="addressSelect"
                      styles={defaultCreatableSelectStyles}
                      filterOption={createFilter({ ignoreAccents: false })}
                    />
                  </Form.Field>
                  <Form.Field width={'2'}>
                    <div style={styles.copyButtons}></div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button size="sm" type="normal" onClick={this.toggleCC}>
                        CC
                      </Button>
                    </div>
                  </Form.Field>
                  <Form.Field width={'2'}>
                    <div style={styles.copyButtons}></div>
                    <div style={{ display: 'flex' }}>
                      <Button size="sm" type="normal" onClick={this.toggleBCC}>
                        BCC
                      </Button>
                    </div>
                  </Form.Field>
                </Form.Group>

                {this.state.showCc && (
                  <Form.Field>
                    <label style={styles.recipientsLabel}>CC</label>
                    <MultiDraggableDropdown
                      name="cc"
                      hideSelectedOptions
                      isClearable
                      formatOptionLabel={this.formatSuggestionOptionLabel}
                      options={this.mapSuggestions(this.props.suggestions)}
                      formatCreateLabel={() => this.formatCreateLabel(t('SELECT_ADD_NEW_CC'))}
                      placeholder={t('ADD_COMMENT_CC_PLACEHOLDER')}
                      noOptionsMessage={() => t('SELECT_NO_OPTIONS')}
                      classNamePrefix="addressSelect"
                      filterOption={createFilter({ ignoreAccents: false })}
                      styles={defaultCreatableSelectStyles}
                    />
                  </Form.Field>
                )}

                {this.state.showBcc && (
                  <Form.Field>
                    <label style={styles.recipientsLabel}>BCC</label>
                    <MultiDraggableDropdown
                      name="bcc"
                      hideSelectedOptions
                      isClearable
                      formatOptionLabel={this.formatSuggestionOptionLabel}
                      options={this.mapSuggestions(this.props.suggestions)}
                      formatCreateLabel={() => this.formatCreateLabel(t('SELECT_ADD_NEW_BCC'))}
                      placeholder={t('ADD_COMMENT_BCC_PLACEHOLDER')}
                      noOptionsMessage={() => t('SELECT_NO_OPTIONS')}
                      classNamePrefix="addressSelect"
                      styles={defaultCreatableSelectStyles}
                      filterOption={createFilter({ ignoreAccents: false })}
                    />
                  </Form.Field>
                )}

                <Divider />

                {FeatureFlags.isFlagOn('ENABLE_EGAIN') && (
                  <Form.Field>
                    <KnowledgeBank
                      title={this.props.subject}
                      id={this.props.taskId}
                      extraArguments={this.props.taskType.knowledgeBank}
                      content={
                        this.state.subject && this.state.subject?.length > 900
                          ? this.state.subject.slice(900, -1) + ' \n\n ' + this.state.content
                          : this.state.content
                      }
                      updateState={(value: string) => {
                        this.updateState({ content: value });
                      }}
                    />
                  </Form.Field>
                )}
                {FeatureFlags.isFlagOn('EMAIL_SEND_FROM_TOP') && this.renderReplyControlButtons()}
                <ReplyVCDrafts taskId={this.props.taskId} channelId={this.channel} onClickLoad={this.loadVCDraft} />

                <Form.Field>
                  <Input
                    fluid
                    type="text"
                    transparent
                    value={this.state.subject}
                    placeholder={t('ADD_COMMENT_TITLE')}
                    onChange={(_, data) => this.updateState({ subject: data.value })}
                  />
                  {/** the title limit 998 but we add extra " [tsk1234567...]"  so limit to 900 to be sure */}
                  {this.state.subject.length >= 900 && (
                    <p style={styles.subjectError}>{t('general_reply.max_length_limit')} (900)</p>
                  )}
                </Form.Field>
                <Form.Field id="commentContentField">
                  {/* <label>{t('ADD_COMMENT_CONTENT')}</label> */}
                  <ReplyEditor
                    style={styles.replyEditorStyle}
                    value={this.state.content}
                    ticket={this.props.task}
                    onChange={(value) => this.updateState({ content: value })}
                    editorLanguage={this.props.userData.language}
                    onKeyDown={this.handleHotKeys}
                    editorRef={this.editorRef}
                  />
                </Form.Field>

                {FeatureFlags.isFlagOn('EMAIL_SEND_FROM_BOTTOM') && this.renderReplyControlButtons()}
              </Form>
            </MultiDraggableDropdownProvider>
          </>
        )}
      </Translation>
    );
  }
}

export default ReplyEmail;
