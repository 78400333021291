import { cloneDeep, get } from 'lodash-es';
import React, { Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { Tab } from 'semantic-ui-react';

import type { Channels, Field, SenderEmail, TicketType } from '@eeedo/types';
import type { TabProps } from 'semantic-ui-react';

import { CommentsContainerContext } from '../Case/CommentsContainerContext';
import { channelNameToType, channelTypeToName } from '../CommentIconContent/ChannelType';
import LoaderComponent from '../Loader/Loader';
import { getReplyChannelPanes } from './getReplyChannelPanes';
import ShortcutsReplyChannel from 'src/Components/Shortcuts/ShortcutsReplyChannel';
import SocketInstance from 'src/realTimeNotifications';
import { checkMandatoryFieldsForTicketClosing } from 'src/Utilities/restrictions';
import { stopWorkingOn } from 'src/Utilities/workStatusParser';

import type { ReplyChannelTabBarProps } from 'src/containers/DraftsContainer';
import type { TicketStatusAfterCommentSubmit } from 'src/types/Salesforce';
import type { Entity } from 'src/types/Ticket';

interface ReplyChannelTabBarState {
  activeIndex: string;
}

export class ReplyChannelTabBar extends React.Component<ReplyChannelTabBarProps, ReplyChannelTabBarState> {
  static contextType = CommentsContainerContext;
  context: React.ContextType<typeof CommentsContainerContext>;

  constructor(props: ReplyChannelTabBarProps) {
    super(props);

    this.state = {
      activeIndex: this.getActiveIndex(props)
    };
  }

  componentWillReceiveProps(nextProps: ReplyChannelTabBarProps) {
    if (this.props.ticket?.id !== nextProps.ticket?.id || this.props.drafts?.tabIndex !== nextProps.drafts?.tabIndex) {
      const activeIndex = this.getActiveIndex(nextProps);
      this.setState({ activeIndex });
    }

    if (nextProps.ticket && this.props.ticket?.channel !== nextProps.ticket?.channel) {
      this.handleTabChange(nextProps.ticket.id, nextProps.ticket.channel);
    }
  }

  public getPanes = () => {
    const {
      t,
      mobileMode,
      ticket,
      userData,
      ticketTypes,
      suggestions,
      users,
      drafts,
      templates,
      uploadFile,
      setChatAnchor,
      sendEntityEvent,
      addOCCallRequest,
      addRingCallRequest,
      addMitelCallRequest,
      updateGiosgWritingStatus,
      updateChatTypingStatusToCustomer
    } = this.props;
    const { shouldShrink } = this.context;

    const panesMap = getReplyChannelPanes({
      t,
      mobileMode,
      canCloseAsDone: this.canCloseAsDone(),
      activeTicketType: this.getActiveTicketTypeId(this.props),
      ticket,
      userData,
      ticketTypes,
      suggestions,
      users,
      drafts,
      templates,
      shouldShrink,
      addTag: this.addTag,
      uploadFile,
      setChatAnchor,
      sendEntityEvent,
      addOCCallRequest,
      addRingCallRequest,
      addMitelCallRequest,
      updateGiosgWritingStatus,
      updateChatTypingStatusToCustomer,
      onSubmit: this.onSubmit,
      getByFieldType: this.getByFieldType,
      updateStateToRedux: this.updateStateToRedux,
      getSenderEmails: this.getSenderEmails,
      changeTicketStatus: this.changeTicketStatus
    });

    const panes = this.isReadOnlyUser()
      ? []
      : this.props.replyTabIndexList.map(({ name }) => panesMap[name]!).filter(Boolean);

    return panes;
  };

  public onTabChange = (_e: React.MouseEvent<HTMLDivElement>, d: TabProps) => {
    const activeIndex = d.activeIndex;
    const channel = this.translateTabToDraftIndex(activeIndex as number);

    this.handleTabChange(this.props.ticket.id, channel);
  };

  protected canCloseAsDone = () => {
    const { ticket, ticketTypes, tags } = this.props;
    if (!ticket || ticket?.id === 'NEW') {
      return false;
    }
    const { status } = checkMandatoryFieldsForTicketClosing(ticket, ticketTypes, tags);
    return status === 'OK';
  };

  private getActiveTicketTypeId = (props: ReplyChannelTabBarProps) => {
    const activeTicketType = props.ticketTypes.find((ticketType) => ticketType.name === props.ticket?.taskType);
    if (!activeTicketType) {
      throw new Error('TicketType mismatch, contact administrator');
    }

    return activeTicketType;
  };

  private changeTicketStatus = async (id: string, UID: string | undefined, status: TicketStatusAfterCommentSubmit) => {
    if (!this.canCloseAsDone) {
      return;
    }
    await this.props.updateTicket({ id, ticket: { status }, closeAfterUpdate: true });

    if (UID !== undefined) {
      await this.props.ticketStopWorkingOn(...stopWorkingOn(UID, id));
    }
    SocketInstance.leaveRoom(id);
  };

  private isReadOnlyUser = (): boolean => {
    return this.props.user.role.name.includes('readOnly');
  };

  private updateStateToRedux = (taskId: string, channel: Channels, state: any) => {
    this.props.updateTicketTabState(taskId, { [channel]: state });
  };

  private getByFieldType = (fieldType: string): string => {
    const entities = this.props.ticket?.entities;
    if (!this.props.ticket || !entities) {
      return '';
    }

    const usedTicketType = this.props.ticketTypes.find((ticketType) => ticketType.name === this.props.ticket.taskType);

    const contactEntities = entities.filter((entity: Entity) =>
      usedTicketType?.entityRouting?.find(
        (eRoute) => eRoute.useAsOutboundContact === true && entity._type === eRoute.entityType
      )
    );
    let entityInfo: Field | Field[] | undefined;

    const correctEntityInfos = usedTicketType?.fieldSets.filter((fieldSet) => {
      if (fieldSet.entityTypes) {
        return (
          fieldSet.id === 'customerInfo' &&
          fieldSet.entityTypes?.find((eType) =>
            usedTicketType?.entityRouting?.find(
              (eRoute) => eRoute.useAsOutboundContact === true && eType === eRoute.entityType
            )
          )
        );
      } else {
        return fieldSet.id === 'customerInfo';
      }
    });

    if (!correctEntityInfos?.length) {
      return '';
    }

    let results: string[] = [];

    correctEntityInfos.forEach((correctEntityInfo): void | string => {
      entityInfo = correctEntityInfo[correctEntityInfo.id];

      if (!entityInfo || !contactEntities || contactEntities?.some((ce) => ce.isLoading)) {
        return '';
      }

      const field = (entityInfo as Field[]).find((field) => field.displayField === fieldType);

      if (field !== undefined) {
        const entityMapper = (entity: Entity) =>
          typeof field.object === 'string'
            ? // return value which is part of an object in the entity
              get(entity?.data, [field.object, field.value])
            : // return value which is a property of the entity
              get(entity?.data, field.value);

        results = contactEntities
          .map(entityMapper)
          // check that field has value
          .filter(Boolean);
      }
    });

    return results.join(',');
  };

  private getActiveIndex = (props: ReplyChannelTabBarProps) => {
    const { drafts, ticket } = props;
    const channel = drafts?.tabIndex !== undefined ? drafts.tabIndex : ticket.channel;

    return channelTypeToName(channel);
  };

  private translateActiveIndexNameToIndex(indexName?: string) {
    return this.props.replyTabIndexList.find((index) => index.name === indexName)?.index;
  }

  private translateTabToDraftIndex(index: number) {
    const indexMatch = this.props.replyTabIndexList.find((tabIndex) => tabIndex.index === index);

    if (!indexMatch) {
      throw new Error(`No reply tab found with index ${index}`);
    }

    const name = indexMatch.name;
    return channelNameToType(name);
  }

  private handleTabChange = (ticketId: string, channel: number) => {
    this.props.switchDraftTab(ticketId, channel);
  };

  private getSenderEmails = (taskType: string, ticketTypes: TicketType[]) => {
    const senderEmails: SenderEmail[] = [];

    ticketTypes.forEach((ticketType) => {
      const senderEmail = cloneDeep(ticketType);

      if (senderEmail.name === taskType) {
        senderEmail.senderemail.default = true;
      }

      if (ticketType.additionalSenderEmails !== undefined && Array.isArray(ticketType.additionalSenderEmails)) {
        // merge multiples into senderEmails
        senderEmails.push(...ticketType.additionalSenderEmails, senderEmail.senderemail);
      } else if (senderEmail.senderemail) {
        // default behavior
        senderEmails.push(senderEmail.senderemail);
      }
    });

    return senderEmails;
  };

  private onSubmit = (body: { [key: string]: any }) => {
    if (this.props.ticket) {
      this.props.onSubmit(this.props.ticket, body);
    }
  };

  private addTag = (tagId: string) => {
    if (this.props.ticket) {
      this.props.addTag(this.props.ticket.id, tagId, false);
    }
  };

  render() {
    return (
      <Suspense fallback={<LoaderComponent />}>
        <Tab
          className="replyChannelTabBar"
          panes={this.getPanes()}
          activeIndex={this.translateActiveIndexNameToIndex(this.state.activeIndex)}
          onTabChange={this.onTabChange}
        />
        <ShortcutsReplyChannel />
      </Suspense>
    );
  }
}

export default withTranslation('translations')(ReplyChannelTabBar);
