// action types
export const ACTIVATE_TICKET = 'ACTIVATE_TICKET';
export const DEACTIVATE_TICKET = 'DEACTIVATE_TICKET';
export const ADD_TICKET = 'ADD_TICKET';
export const CLEAR_DETAILED_TICKETS = 'CLEAR_DETAILED_TICKETS';

export const FETCH_PERSONAL_DATA_SUCCESS = 'FETCH_PERSONAL_DATA_SUCCESS';
export const FETCH_PERSONAL_DATA_FAILURE = 'FETCH_PERSONAL_DATA_FAILURE';

export const UPDATE_PERSONAL_PROFILE = 'UPDATE_PERSONAL_PROFILE';

export const ACTIVATE_TICKETLIST = 'ACTIVATE_TICKETLIST';
export const DEACTIVATE_TICKETLIST = 'DEACTIVATE_TICKETLIST';
export const ADD_TICKETLIST = 'ADD_TICKETLIST';
export const REMOVE_TICKETLIST = 'REMOVE_TICKETLIST';

export const APPEND_TO_DRAFT = 'APPEND_TO_DRAFT';

export const SWITCH_DRAFT_TAB = 'SWITCH_DRAFT_TAB';

export const SEARCH_ENTITY_BY_DETAILS_SUCCESS = 'SEARCH_ENTITY_BY_DETAILS_SUCCESS';

export const SWITCH_TICKET_CHAT_ANCHOR_STATUS = 'SWITCH_TICKET_CHAT_ANCHOR_STATUS';

export const FETCH_TICKET_TYPES_METADATA_SUCCESS = 'FETCH_TICKET_TYPES_METADATA_SUCCESS';
export const FETCH_TICKET_TYPES_FAILURE = 'FETCH_TICKET_TYPES_FAILURE';
export const FETCH_RESPONSE_TEMPLATES_FAILURE = 'FETCH_RESPONSE_TEMPLATES_FAILURE';
export const FETCH_TITLE_TEMPLATES_FAILURE = 'FETCH_TITLE_TEMPLATES_FAILURE';

export const FETCH_CHANNEL_TYPES_SUCCESS = 'FETCH_CHANNEL_TYPES_SUCCESS';
export const FETCH_CHANNEL_TYPES_FAILURE = 'FETCH_CHANNEL_TYPES_FAILURe';
export const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS';
export const FETCH_INFOPAGES_SUCCESS = 'FETCH_INFOPAGES_SUCCESS';
export const FETCH_TICKETS_ERROR = 'FETCH_TICKETS_ERROR';
export const FETCH_INFOPAGES_ERROR = 'FETCH_INFOPAGES_ERROR';
export const FETCH_LINKED_TICKETS_SUCCESS = 'FETCH_LINKED_TICKETS_SUCCESS';
export const FETCH_LINKED_TICKETS_ERROR = 'FETCH_LINKED_TICKETS_ERROR';
export const RESET_LINKED_TICKETS = 'RESET_LINKED_TICKETS';
export const FETCH_TICKET_SUCCESS = 'FETCH_TICKET_SUCCESS';
export const ADD_TICKET_TO_TICKETLIST = 'ADD_TICKET_TO_TICKETLIST';
export const REMOVE_TICKET_FROM_TICKETLIST = 'REMOVE_TICKET_FROM_TICKETLIST';
export const UPDATE_TICKET_IN_TICKETLIST = 'UPDATE_TICKET_IN_TICKETLIST';
export const PARSE_TICKET_COMMENTS_SUCCESS = 'PARSE_TICKET_COMMENTS_SUCCESS';

export const UPDATE_TICKET = 'UPDATE_TICKET';
export const UPDATE_TICKET_SUCCESS = 'UPDATE_TICKET_SUCCESS';
export const UPDATE_TICKET_FAILURE = 'UPDATE_TICKET_FAILURE';
export const UPDATE_INFOPAGE_SUCCESS = 'UPDATE_INFOPAGE_SUCCESS';
export const UPDATE_INFOPAGE_FAILURE = 'UPDATE_INFOPAGE_FAILURE';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';

export const UPDATE_METADATA_SUCCESS = 'UPDATE_METADATA_SUCCESS';
export const UPDATE_METADATA_FAILURE = 'UPDATE_METADATA_FAILURE';

export const CREATE_TICKET = 'CREATE_TICKET';
export const CREATE_INFO_PAGE = 'CREATE_INFO_PAGE';
export const CREATE_TICKET_SUCCESS = 'CREATE_TICKET_SUCCESS';
export const CREATE_INFO_PAGE_SUCCESS = 'CREATE_INFO_PAGE_SUCCESS';

export const START_AJAX_CALL = 'START_AJAX_CALL';

export const ADD_TAB = 'ADD_TAB';
export const CLOSE_TAB = 'CLOSE_TAB';
export const ACTIVATE_TAB = 'ACTIVATE_TAB';
export const HIGHLIGHT_TAB_ON = 'HIGHLIGHT_TAB_ON';
export const HIGHLIGHT_TAB_OFF = 'HIGHLIGHT_TAB_OFF';

export const ADD_TICKETLIST_TAB = 'ADD_TICKETLIST_TAB';
export const ADD_INFOPAGELIST_TAB = 'ADD_INFOPAGELIST_TAB';
export const CLOSE_TICKETLIST_TAB = 'CLOSE_TICKETLIST_TAB';
export const CLOSE_INFOPAGELIST_TAB = 'CLOSE_INFOPAGELIST_TAB';
export const ACTIVATE_TICKETLIST_TAB = 'ACTIVATE_TICKETLIST_TAB';
export const ACTIVATE_INFOPAGELIST_TAB = 'ACTIVATE_INFOPAGELIST_TAB';
export const UPDATE_INFOPAGE_IN_INFOPAGELIST = 'UPDATE_INFOPAGE_IN_INFOPAGELIST';
export const REMOVE_INFOPAGE_FROM_INFOPAGELIST = 'REMOVE_INFOPAGE_FROM_INFOPAGELIST';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';

export const FETCH_CASE_IMPORT_TEMPLATES_FAILURE = 'FETCH_CASE_IMPORT_TEMPLATES_FAILURE';

export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';
export const PATCH_TAGS = 'PATCH_TAGS';
export const PATCH_TAGS_SUCCESS = 'PATCH_TAGS_SUCCESS';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const PATCH_CATEGORY = 'PATCH_CATEGORY';
export const PATCH_CATEGORY_SUCCESS = 'PATCH_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';

export const FETCH_POST_INTEGRATION = 'FETCH_POST_INTEGRATION';
export const FETCH_POST_INTEGRATION_SUCCESS = 'FETCH_POST_INTEGRATION_SUCCESS';
export const FETCH_POST_INTEGRATION_FAILURE = 'FETCH_POST_INTEGRATION_FAILURE';
export const PATCH_POST_INTEGRATION = 'PATCH_POST_INTEGRATION';
export const PATCH_POST_INTEGRATION_SUCCESS = 'PATCH_POST_INTEGRATION_SUCCESS';

export const FETCH_LINKLISTS = 'FETCH_LINKLISTS';
export const FETCH_LINKLISTS_SUCCESS = 'FETCH_LINKLISTS_SUCCESS';
export const FETCH_LINKLISTS_FAILURE = 'FETCH_LINKLISTS_FAILURE';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE_CREDENTIALS = 'LOGIN_FAILURE_CREDENTIALS';
export const LOGIN_FAILURE_GENERAL = 'LOGIN_FAILURE_GENERAL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const GET_ENTITY_BY_ID_SUCCESS = 'GET_ENTITY_BY_ID_SUCCESS';
export const GET_ENTITY_BY_ID_FAILURE = 'GET_ENTITY_BY_ID_FAILURE';
export const GET_SUB_ENTITY_BY_ID_SUCCESS = 'GET_SUB_ENTITY_BY_ID_SUCCESS';
export const GET_SUB_ENTITY_BY_ID_FAILURE = 'GET_SUB_ENTITY_BY_ID_FAILURE';
export const GET_ENTITY = 'GET_ENTITY';

export const ADDED_ATTACHMENT_TO_TICKET = 'ADDED_ATTACHMENT_TO_TICKET';

export const ADD_ENTITY_SUCCESS = 'ADD_ENTITY_SUCCESS';

export const UPDATE_WORK_STATUS = 'UPDATE_WORK_STATUS';
export const UPDATE_WORK_RECORDING_INFO = 'UPDATE_WORK_RECORDING_INFO';

export const UPDATE_TICKET_TAB_STATE = 'UPDATE_TICKET_TAB_STATE';

export const UPDATE_INITIAL_REQUEST_STATUS_INIT = 'UPDATE_INITIAL_REQUEST_STATUS_INIT';
export const UPDATE_INITIAL_REQUEST_STATUS_SUCCESS = 'UPDATE_INITIAL_REQUEST_STATUS_SUCCESS';
export const UPDATE_INITIAL_REQUEST_STATUS_FAILURE = 'UPDATE_INITIAL_REQUEST_STATUS_FAILURE';
export const UPDATE_INITIAL_REQUEST_STATUS_LOADING = 'UPDATE_INITIAL_REQUEST_STATUS_LOADING';

export const SWIPE_RIGHT = 'SWIPE_RIGHT';
export const SWIPE_LEFT = 'SWIPE_LEFT';
export const TOGGLE_MOBILE_MODE = 'TOGGLE_MOBILE_MODE';

export const ADD_CALL_REQUEST = 'ADD_CALL_REQUEST';
export const REMOVE_CALL_REQUEST = 'REMOVE_CALL_REQUEST';

export const SET_ENREACH_VOICE_LOGGED_IN = 'SET_ENREACH_VOICE_LOGGED_IN';
export const SET_ENREACH_VOICE_STATUS = 'SET_ENREACH_VOICE_STATUS';

export const CLEAR_TICKETLIST_FILTER = 'CLEAR_TICKETLIST_FILTER';
export const CLEAR_INFOPAGELIST_FILTER = 'CLEAR_INFOPAGELIST_FILTER';
export const SET_TICKETLIST_FILTER = 'SET_TICKETLIST_FILTER';
export const SET_INFOPAGELIST_FILTER = 'SET_INFOPAGELIST_FILTER';

export const SET_TICKETLIST_SORTING = 'SET_TICKETLIST_SORTING';

export const SET_TICKETLIST_SEARCH = 'SET_TICKETLIST_SEARCH';
export const SET_INFOPAGELIST_SEARCH = 'SET_INFOPAGELIST_SEARCH';

export const SET_TICKETLIST_ACCORDION = 'SET_TICKETLIST_ACCORDION';
export const SET_INFOPAGELIST_ACCORDION = 'SET_INFOPAGELIST_ACCORDION';

export const SET_TICKETLIST_ROW_INDEX = 'SET_TICKETLIST_ROW_INDEX';

export const FETCH_CHANNELS_SUCCESS = 'FETCH_CHANNELS_SUCCESS';
export const FETCH_CHANNELS_FAILURE = 'FETCH_CHANNELS_FAILURE';
export const PATCH_CHANNELS_SUCCESS = 'PATCH_CHANNELS_SUCCESS';

export const FETCH_AUTO_SUGGESTIONS_SUCCESS = 'FETCH_AUTO_SUGGESTIONS_SUCCESS';
export const CREATE_AUTO_SUGGESTION_SUCCESS = 'CREATE_AUTO_SUGGESTION_SUCCESS';
export const DELETE_AUTO_SUGGESTION_SUCCESS = 'DELETE_AUTO_SUGGESTION_SUCCESS';
export const UPDATE_AUTO_SUGGESTION_SUCCESS = 'UPDATE_AUTO_SUGGESTION_SUCCESS';

export const SAVE_ENTITY_CHANGE = 'SAVE_ENTITY_CHANGE';

export const SCROLL_CASE_BOTTOM_INSTANTLY = 'SCROLL_CASE_BOTTOM_INSTANTLY';

export const SET_SETTINGS_TAB = 'SET_SETTINGS_TAB';

export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';

export const FETCH_TICKET_PRIORITIES_SUCCESS = 'FETCH_TICKET_PRIORITIES_SUCCESS';
export const FETCH_TICKET_PRIORITIES_ERROR = 'FETCH_TICKET_PRIORITIES_ERROR';

export const ADD_PRIORITIES_SUCCESS = 'ADD_PRIORITIES_TYPES_SUCCESS';
export const FETCH_PRIORITIES_SUCCESS = 'FETCH_PRIORITIES_SUCCESS';
export const PATCH_PRIORITIES_SUCCESS = 'PATCH_PRIORITIES_TYPES_SUCCESS';
export const FETCH_PRIORITIES_FAILURE = 'FETCH_PRIORITIES_FAILURE';

export const FETCH_PHONE_CONFIGURATIONS_SUCCESS = 'FETCH_PHONE_CONFIGURATIONS_SUCCESS';

// for userTimeLogs
export const PATCH_USER_NOTIFICATIONS_SUCCESS = 'PATCH_USER_NOTIFICATIONS_SUCCESS';

export const FETCH_CHAT_STATUSES_SUCCESS = 'FETCH_CHAT_STATUSES_SUCCESS';
export const FETCH_CHAT_STATUS_SUCCESS = 'FETCH_CHAT_STATUS_SUCCESS';

export const FETCH_TEMPLATES_FAILURE = 'FETCH_TEMPLATES_FAILURE';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const REMOVE_ALL_NOTIFICATIONS = 'REMOVE_ALL_NOTIFICATIONS';
export const SHOW_DISCONNECTED_NOTIFICATION = 'SHOW_DISCONNECTED_NOTIFICATION';

export const ADD_USER_TICKET_TYPE = 'ADD_USER_TICKET_TYPE';
export const REMOVE_USER_TICKET_TYPE = 'REMOVE_USER_TICKET_TYPE';
export const ADD_USER_CHANNEL = 'ADD_USER_CHANNEL';
export const REMOVE_USER_CHANNEL = 'REMOVE_USER_CHANNEL';
export const ADD_FORBIDDEN_LOGIN_STRATEGY = 'ADD_FORBIDDEN_LOGIN_STRATEGY';
export const REMOVE_FORBIDDEN_LOGIN_STRATEGY = 'REMOVE_FORBIDDEN_LOGIN_STRATEGY';

export const OPEN_ATTACHMENTS_PREVIEW = 'OPEN_ATTACHMENTS_PREVIEW';
export const CLOSE_ATTACHMENTS_PREVIEW = 'CLOSE_ATTACHMENTS_PREVIEW';

export const CASE_SET_IFRAME_URL = 'CASE_SET_IFRAME_URL';
export const CASE_REMOVE_IFRAME_URL = 'CASE_REMOVE_IFRAME_URL';
export const CASE_ACTIVATE_IFRAME_TAB = 'CASE_ACTIVATE_IFRAME_TAB';

export const SET_COMMENT_FILTER = 'SET_COMMENT_FILTER';
export const CLEAR_COMMENT_FILTER = 'CLEAR_COMMENT_FILTER';

export const FETCH_EIDENTIFICATIONS_SUCCESS = 'FETCH_EIDENTIFICATIONS_SUCCESS';
export const UPDATE_EIDENTIFICATIONS_SUCCESS = 'UPDATE_EIDENTIFICATIONS_SUCCESS';
export const GENERATE_EIDENTIFICATION_LINK_SUCCESS = 'GENERATE_EIDENTIFICATION_LINK_SUCCESS';
export const GET_EIDENTIFICATION_LINK_SUCCESS = 'GET_EIDENTIFICATION_LINK_SUCCESS';

export const FETCH_EIDENTIFICATION_RELATON_SUCCESS = 'FETCH_EIDENTIFICATION_RELATON_SUCCESS';

export const GENERATE_TAGS_SUCCESS = 'GENERATE_TAGS_SUCCESS';

export const ROOM_SUBSCRIBER_CLOSED = 'ROOM_SUBSCRIBER_CLOSED';

export const REMOVE_TICKET_FROM_DETAILED = 'REMOVE_TICKET_FROM_DETAILED';

export const FETCH_ENTITY_TAGS_SUCCESS = 'FETCH_ENTITY_TAGS_SUCCESS';
export const UPDATE_ENTITY_TAGS_SUCCESS = 'UPDATE_ENTITY_TAGS_SUCCESS';
export const DELETE_ENTITY_TAGS_SUCCESS = 'DELETE_ENTITY_TAGS_SUCCESS';

export const FETCH_WEBHOOKS_SUCCESS = 'FETCH_WEBHOOKS_SUCCESS';

export const FETCH_USER_STATUSES_SUCCESS = 'FETCH_USER_STATUSES_SUCCESS';
export const FETCH_CURRENT_USER_STATUSES_SUCCESS = 'FETCH_CURRENT_USER_STATUSES_SUCCESS';
export const CHANGE_CURRENT_USER_STATUS_SUCCESS = 'CHANGE_CURRENT_USER_STATUS_SUCCESS';
export const FETCH_USER_STATUSES_LOGS_SUCCESS = 'FETCH_USER_STATUSES_LOGS_SUCCESS';
export const FETCH_CHANNELS_AVAILABILITY_SUCCESS = 'FETCH_CHANNELS_AVAILABILITY_SUCCESS';
export const CHANGE_CHANNELS_AVAILABILITY_SUCCESS = 'CHANGE_CHANNELS_AVAILABILITY_SUCCESS';
export const REMOVE_CHANNELS_AVAILABILITY_SUCCESS = 'REMOVE_CHANNELS_AVAILABILITY_SUCCESS';
